@media only screen and (max-width: 620px) {
  .RichEditorTheme__paragraph {
    font-size: 1rem !important;
  }
}

.Placeholder__root {
  @apply opacity-50 top-4;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  left: 28px;
  right: 28px;
  user-select: none;
  white-space: nowrap;
  display: inline-block;
  pointer-events: none;
}

.ContentEditable__root {
  border: 0;
  font-size: 15px;
  display: block;
  position: relative;
  tab-size: 1;
  outline: 0;
  min-height: calc(100% - 16px);
}

.Collapsible__container {
  background: #fcfcfc;
  border: 1px solid #eee;
  border-radius: 10px;
  margin-bottom: 8px;
}

.Collapsible__title {
  cursor: pointer;
  padding: 5px 5px 5px 20px;
  position: relative;
  font-weight: bold;
  list-style: none;
  outline: none;
}

.Collapsible__title::marker,
.Collapsible__title::-webkit-details-marker {
  display: none;
}

.Collapsible__title:before {
  border-style: solid;
  border-color: transparent;
  border-width: 4px 6px 4px 6px;
  border-left-color: #000;
  display: block;
  content: '';
  position: absolute;
  left: 7px;
  top: 15px;
}

.Collapsible__container[open] .Collapsible__title:before {
  border-color: transparent;
  border-width: 6px 4px 6px 4px;
  border-top-color: #000;
}

.Collapsible__content {
  padding: 0 5px 5px 20px;
}

.Collapsible__collapsed .Collapsible__content {
  display: none;
  user-select: none;
}

.editor-shell {
  @apply relative;
  max-width: 1100px;
  line-height: 1.7;
  font-weight: 400;
}

.editor-shell .editor-container {
  @apply relative block cursor-text;
}

.editor-shell .editor-container.tree-view {
  border-radius: 0;
}

.editor-shell .editor-container.plain-text {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.editor {
  height: 100%;
  position: relative;
}

.test-recorder-output {
  margin: 20px auto 20px auto;
  width: 100%;
}

.editor-dev-button {
  position: relative;
  display: block;
  width: 40px;
  height: 40px;
  font-size: 12px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  outline: none;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.3);
  background-color: #444;
}

.editor-dev-button::after {
  content: '';
  position: absolute;
  top: 10px;
  right: 10px;
  bottom: 10px;
  left: 10px;
  display: block;
  background-size: contain;
  filter: invert(1);
}

.editor-dev-button:hover {
  background-color: #555;
}

.editor-dev-button.active {
  background-color: rgb(233, 35, 35);
}

.test-recorder-toolbar {
  display: flex;
}

.test-recorder-button {
  position: relative;
  display: block;
  width: 32px;
  height: 32px;
  font-size: 10px;
  padding: 6px 6px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  outline: none;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.4);
  background-color: #222;
  transition: box-shadow 50ms ease-out;
}

.test-recorder-button:active {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.4);
}

.test-recorder-button + .test-recorder-button {
  margin-left: 4px;
}

.test-recorder-button::after {
  content: '';
  position: absolute;
  top: 8px;
  right: 8px;
  bottom: 8px;
  left: 8px;
  display: block;
  background-size: contain;
  filter: invert(1);
}

#options-button {
  position: fixed;
  left: 20px;
  bottom: 20px;
}

#test-recorder-button {
  position: fixed;
  left: 70px;
  bottom: 20px;
}

#paste-log-button {
  position: fixed;
  left: 120px;
  bottom: 20px;
}

#test-recorder-button-snapshot {
  margin-right: auto;
}

.typeahead-popover {
  background: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  margin-top: 25px;
}

.typeahead-popover ul {
  padding: 0;
  list-style: none;
  margin: 0;
  border-radius: 8px;
  max-height: 200px;
  overflow-y: scroll;
}

.typeahead-popover ul::-webkit-scrollbar {
  display: none;
}

.typeahead-popover ul {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.typeahead-popover ul li {
  margin: 0;
  min-width: 180px;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  border-radius: 8px;
}

.typeahead-popover ul li.selected {
  background: #eee;
}

.typeahead-popover li {
  margin: 0 8px 0 8px;
  padding: 8px;
  color: #050505;
  cursor: pointer;
  line-height: 16px;
  font-size: 15px;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-shrink: 0;
  background-color: #fff;
  border-radius: 8px;
  border: 0;
}

.typeahead-popover li.active {
  display: flex;
  width: 20px;
  height: 20px;
  background-size: contain;
}

.typeahead-popover li:first-child {
  border-radius: 8px 8px 0px 0px;
}

.typeahead-popover li:last-child {
  border-radius: 0px 0px 8px 8px;
}

.typeahead-popover li:hover {
  background-color: #eee;
}

.typeahead-popover li .text {
  display: flex;
  line-height: 20px;
  flex-grow: 1;
  min-width: 150px;
}

.typeahead-popover li .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 8px;
  line-height: 16px;
  background-size: contain;
}

.component-picker-menu {
  width: 200px;
}

.mentions-menu {
  width: 250px;
}

.auto-embed-menu {
  width: 150px;
}

.emoji-menu {
  width: 200px;
}

.link-editor .button.active,
.toolbar .button.active {
  background-color: rgb(223, 232, 250);
}

.link-editor .link-input {
  width: calc(100% - 24px);
  box-sizing: border-box;
  font-size: 15px;
}

.link-editor div.link-edit {
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  width: 35px;
  vertical-align: -0.25em;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
}

.link-editor .link-input a,
.link-editor .link-input button {
  @apply text-primary;
  text-decoration: none;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 30px;
  text-overflow: ellipsis;
  max-width: 100%;
}

.link-editor .link-input a:hover,
.link-editor .link-input button:hover {
  text-decoration: underline;
}

.link-editor .font-size-wrapper,
.link-editor .font-family-wrapper {
  display: flex;
  margin: 0 4px;
}

.link-editor select {
  padding: 6px;
  border: none;
  background-color: rgba(0, 0, 0, 0.075);
  border-radius: 4px;
}

.mention:focus {
  box-shadow: rgb(180 213 255) 0px 0px 0px 2px;
  outline: none;
}

#block-controls {
  display: block;
  position: absolute;
  right: 10px;
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  top: 16px;
  z-index: 10;
  border-radius: 8px;
  border: 1px solid rgb(206, 208, 212);
  overflow: hidden;
}

#block-controls button {
  border: 1px solid white;
  background-color: #fff;
  display: block;
  transition: background-color 0.1s ease;
  cursor: pointer;
  outline: none;
  border-radius: 8px;
  padding: 3px;
}

#block-controls button:hover {
  background-color: #efefef;
}

#block-controls button:focus-visible {
  border-color: blue;
}

#block-controls span.block-type {
  background-size: contain;
  display: block;
  width: 18px;
  height: 18px;
  margin: 2px;
}

.characters-limit {
  color: #888;
  font-size: 12px;
  text-align: right;
  display: block;
  position: absolute;
  left: 12px;
  bottom: 5px;
}

.characters-limit.characters-limit-exceeded {
  color: red;
}

.switches {
  z-index: 6;
  position: fixed;
  left: 10px;
  bottom: 70px;
  animation: slide-in 0.4s ease;
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateX(-200px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.switch {
  display: block;
  color: #444;
  margin: 5px 0;
  background-color: rgba(238, 238, 238, 0.7);
  padding: 5px 10px;
  border-radius: 10px;
}

#rich-text-switch {
  right: 0;
}

#character-count-switch {
  right: 130px;
}

.switch label {
  margin-right: 5px;
  line-height: 24px;
  width: 100px;
  font-size: 14px;
  display: inline-block;
  vertical-align: middle;
}

.switch button {
  background-color: rgb(206, 208, 212);
  height: 24px;
  box-sizing: border-box;
  border-radius: 12px;
  width: 44px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  outline: none;
  cursor: pointer;
  transition: background-color 0.1s;
  border: 2px solid transparent;
}

.switch button:focus-visible {
  border-color: blue;
}

.switch button span {
  top: 0px;
  left: 0px;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 12px;
  background-color: white;
  transition: transform 0.2s;
}

.switch button[aria-checked='true'] {
  background-color: rgb(24, 119, 242);
}

.switch button[aria-checked='true'] span {
  transform: translateX(20px);
}

.actions {
  position: absolute;
  text-align: right;
  padding: 10px;
  bottom: -48px;
  right: 0;
}

.actions.tree-view {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.actions i {
  background-size: contain;
  display: inline-block;
  height: 15px;
  width: 15px;
  vertical-align: -0.25em;
}

i.chevron-down {
  background-color: transparent;
  background-size: contain;
  display: inline-block;
  height: 8px;
  width: 8px;
}

.action-button {
  background-color: #eee;
  border: 0;
  padding: 8px 12px;
  position: relative;
  margin-left: 5px;
  border-radius: 15px;
  color: #222;
  display: inline-block;
  cursor: pointer;
}

.action-button:hover {
  background-color: #ddd;
  color: #000;
}

.action-button-mic.active {
  animation: mic-pulsate-color 3s infinite;
}
button.action-button:disabled {
  opacity: 0.6;
  background: #eee;
  cursor: not-allowed;
}

@keyframes mic-pulsate-color {
  0% {
    background-color: #ffdcdc;
  }
  50% {
    background-color: #ff8585;
  }
  100% {
    background-color: #ffdcdc;
  }
}

.debug-timetravel-panel {
  overflow: hidden;
  padding: 0 0 10px 0;
  margin: auto;
  display: flex;
}

.debug-timetravel-panel-slider {
  padding: 0;
  flex: 8;
}

.debug-timetravel-panel-button {
  padding: 0;
  border: 0;
  background: none;
  flex: 1;
  color: #fff;
  font-size: 12px;
}

.debug-timetravel-panel-button:hover {
  text-decoration: underline;
}

.debug-timetravel-button {
  border: 0;
  padding: 0;
  font-size: 12px;
  top: 10px;
  right: 15px;
  position: absolute;
  background: none;
  color: #fff;
}

.debug-timetravel-button:hover {
  text-decoration: underline;
}

.connecting {
  font-size: 15px;
  color: #999;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 10px;
  left: 10px;
  user-select: none;
  white-space: nowrap;
  display: inline-block;
  pointer-events: none;
}

.ltr {
  text-align: left;
}

.rtl {
  text-align: right;
}

.toolbar {
  @apply bg-surface flex gap-1;
  margin-bottom: 1px;
  vertical-align: middle;
}

.toolbar button.toolbar-item {
  @apply flex rounded cursor-pointer items-center justify-between p-2;
  vertical-align: middle;
  flex-shrink: 0;
}

.toolbar .toolbar-item:hover:not([disabled]) {
  @apply bg-primary/20;
}

.toolbar button.toolbar-item:disabled {
  cursor: not-allowed;
}

.toolbar button.toolbar-item i.format {
  background-size: contain;
  display: inline-block;
  height: 18px;
  width: 18px;
  vertical-align: -0.25em;
  display: flex;
  opacity: 0.6;
}

.toolbar button.toolbar-item:disabled .icon,
.toolbar button.toolbar-item:disabled .text,
.toolbar button.toolbar-item:disabled i.format,
.toolbar button.toolbar-item:disabled .chevron-down {
  opacity: 0.2;
}

.toolbar button.toolbar-item.active {
  @apply bg-primary/10;
}

.toolbar button.toolbar-item.active i {
  opacity: 1;
}

.toolbar .toolbar-item.font-family .text {
  display: block;
  max-width: 40px;
}

.toolbar .code-language {
  width: 150px;
}

.toolbar .toolbar-item .text {
  display: flex;
  line-height: 20px;
  vertical-align: middle;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 20px;
  text-align: left;
}

.toolbar .toolbar-item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 8px;
  line-height: 16px;
  background-size: contain;
}

.toolbar i.chevron-down {
  margin-top: 3px;
  width: 16px;
  height: 16px;
  display: flex;
  user-select: none;
}

.toolbar i.chevron-down.inside {
  width: 16px;
  height: 16px;
  display: flex;
  margin-left: -25px;
  margin-top: 11px;
  margin-right: 10px;
  pointer-events: none;
}

.toolbar .divider {
  width: 1px;
  background-color: #eee;
  margin: 0 4px;
}

.spacer {
  letter-spacing: -2px;
}

div[data-lexical-decorator='true'] hr {
  @apply border-0 h-auto mt-7;
}
div[data-lexical-decorator='true'] hr:after {
  @apply block border-0 text-center h-auto text-5xl;
  content: '· · ·';
}

div[data-lexical-decorator='true'] hr.selected {
  outline: 2px solid rgb(60, 132, 244);
  user-select: none;
}

/* FloatingLink */
.link-editor {
  opacity: 0;
  top: -10000px;
  left: -10000px;
  transition: opacity 0.5s;
}

.link-editor .button {
  width: 20px;
  height: 20px;
  display: inline-block;
  padding: 6px;
  border-radius: 8px;
  cursor: pointer;
  margin: 0 2px;
}

.link-editor .button.hovered {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-color: #eee;
}

.link-editor .button i,
.actions i {
  background-size: contain;
  display: inline-block;
  height: 20px;
  width: 20px;
  vertical-align: -0.25em;
}

/* RichEditorTheme.css */

.RichEditorTheme__ltr {
  text-align: left;
}
.RichEditorTheme__paragraph {
  @apply first:mt-0 mt-2;
  position: relative;
}
.RichEditorTheme__quote {
  margin: 0;
  margin-left: 20px;
  margin-bottom: 10px;
  font-size: 15px;
  color: rgb(101, 103, 107);
  border-left-color: rgb(206, 208, 212);
  border-left-width: 4px;
  border-left-style: solid;
  padding-left: 16px;
}
.RichEditorTheme__h1 {
  @apply font-sans text-2xl sm:text-4xl py-0.5 first:mt-0 mt-7;
}
.RichEditorTheme__h2 {
  @apply font-sans text-xl sm:text-3xl first:mt-0 mt-7;
}

.RichEditorTheme__h3 {
  @apply font-sans text-lg sm:text-xl first:mt-0 mt-7;
}
.RichEditorTheme__h4 {
  @apply font-sans text-base sm:text-xl first:mt-0 mt-7;
}
.RichEditorTheme__h5 {
  @apply text-sm sm:text-base first:mt-0 mt-7;
}
.RichEditorTheme__h6 {
  @apply font-sans text-sm first:mt-0 mt-7;
}

.RichEditorTheme__textBold {
  font-weight: bold;
}
.RichEditorTheme__textItalic {
  font-style: italic;
}
.RichEditorTheme__textUnderline {
  text-decoration: underline;
}
.RichEditorTheme__textStrikethrough {
  text-decoration: line-through;
}
.RichEditorTheme__textUnderlineStrikethrough {
  text-decoration: underline line-through;
}

.RichEditorTheme__link {
  @apply text-primary;
  text-decoration: none;
}
.RichEditorTheme__link:hover {
  text-decoration: underline;
}
.RichEditorTheme__characterLimit {
  display: inline;
  background-color: #ffbbbb !important;
}
.RichEditorTheme__ol1 {
  padding: 0;
  margin: 0;
  margin-left: 8px;
  list-style-type: decimal;
}
.RichEditorTheme__ol2 {
  padding: 0;
  margin: 0;
  margin-left: 16px;
  list-style-type: upper-alpha;
  list-style-position: inside;
}
.RichEditorTheme__ol3 {
  padding: 0;
  margin: 0;
  margin-left: 16px;
  list-style-type: lower-alpha;
  list-style-position: inside;
}
.RichEditorTheme__ol4 {
  padding: 0;
  margin: 0;
  margin-left: 16px;
  list-style-type: upper-roman;
  list-style-position: inside;
}
.RichEditorTheme__ol5 {
  padding: 0;
  margin: 0;
  margin-left: 16px;
  list-style-type: lower-roman;
  list-style-position: inside;
}
.RichEditorTheme__ul {
  padding: 0;
  margin: 0;
  margin-left: 8px;
  list-style-type: disc;
}
.RichEditorTheme__listItem {
  @apply first:mt-7 only:my-0 mt-4 mx-8;
}
.RichEditorTheme__listItemChecked,
.RichEditorTheme__listItemUnchecked {
  position: relative;
  margin-left: 8px;
  margin-right: 8px;
  padding-left: 24px;
  padding-right: 24px;
  list-style-type: none;
  outline: none;
}
.RichEditorTheme__listItemChecked {
  text-decoration: line-through;
}
.RichEditorTheme__listItemUnchecked:before,
.RichEditorTheme__listItemChecked:before {
  content: '';
  width: 16px;
  height: 16px;
  top: 2px;
  left: 0;
  cursor: pointer;
  display: block;
  background-size: cover;
  position: absolute;
}
.RichEditorTheme__listItemUnchecked[dir='rtl']:before,
.RichEditorTheme__listItemChecked[dir='rtl']:before {
  left: auto;
  right: 0;
}
.RichEditorTheme__listItemUnchecked:focus:before,
.RichEditorTheme__listItemChecked:focus:before {
  box-shadow: 0 0 0 2px #a6cdfe;
  border-radius: 2px;
}
.RichEditorTheme__listItemUnchecked:before {
  border: 1px solid #999;
  border-radius: 2px;
}
.RichEditorTheme__listItemChecked:before {
  border: 1px solid rgb(61, 135, 245);
  border-radius: 2px;
  background-color: #3d87f5;
  background-repeat: no-repeat;
}
.RichEditorTheme__listItemChecked:after {
  content: '';
  cursor: pointer;
  border-color: #fff;
  border-style: solid;
  position: absolute;
  display: block;
  top: 6px;
  width: 3px;
  left: 7px;
  height: 6px;
  transform: rotate(45deg);
  border-width: 0 2px 2px 0;
}
.RichEditorTheme__nestedListItem {
  list-style-type: none;
}
.RichEditorTheme__nestedListItem:before,
.RichEditorTheme__nestedListItem:after {
  display: none;
}
.RichEditorTheme__mark {
  background: rgba(255, 212, 0, 0.14);
  border-bottom: 2px solid rgba(255, 212, 0, 0.3);
  padding-bottom: 2px;
}
.RichEditorTheme__markOverlap {
  background: rgba(255, 212, 0, 0.3);
  border-bottom: 2px solid rgba(255, 212, 0, 0.7);
}
.RichEditorTheme__mark.selected {
  background: rgba(255, 212, 0, 0.5);
  border-bottom: 2px solid rgba(255, 212, 0, 1);
}
.RichEditorTheme__markOverlap.selected {
  background: rgba(255, 212, 0, 0.7);
  border-bottom: 2px solid rgba(255, 212, 0, 0.7);
}
.RichEditorTheme__embedBlock {
  @apply flex justify-center py-4;
  user-select: none;
}
.RichEditorTheme__embedBlockFocus {
  @apply rounded-xl ring-2 shadow-xl;
}

/* LEXICAL DROPDOWN */

.lexical-dropdown {
  @apply border border-dashed border-primary/20 block z-50 space-y-2 px-2 rounded-xl shadow bg-background fixed;
  min-height: 40px;
}

.lexical-dropdown .item {
  @apply bg-surface p-2 flex gap-3 flex-1 w-full;

  cursor: pointer;
  line-height: 16px;
  font-size: 15px;
  align-content: center;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  border-radius: 8px;
  border: 0;
  max-width: 250px;
  min-width: 100px;
}

.lexical-dropdown .item.fontsize-item,
.lexical-dropdown .item.fontsize-item .text {
  min-width: unset;
}

.lexical-dropdown .item .active {
  display: flex;
  width: 20px;
  height: 20px;
  background-size: contain;
}

.lexical-dropdown .item:first-child {
  margin-top: 8px;
}

.lexical-dropdown .item:last-child {
  margin-bottom: 8px;
}

.lexical-dropdown .item:hover {
  @apply bg-background/50;
}

.lexical-dropdown .item .text {
  display: flex;
  line-height: 20px;
  flex-grow: 1;
  min-width: 150px;
}

.lexical-dropdown .item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 12px;
  line-height: 16px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.lexical-dropdown .divider {
  width: auto;
  background-color: #eee;
  margin: 4px 8px;
  height: 1px;
}

@media screen and (max-width: 1100px) {
  .lexical-dropdown-button-text {
    display: none;
  }
  .font-size .lexical-dropdown-button-text {
    display: flex;
  }
  .code-language .lexical-dropdown-button-text {
    display: flex;
  }
}

/* END LEXICAL DROPDOWN */

/* LEXICAL Modal */

/* Modal */
.Modal__overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  flex-direction: column;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(40, 40, 40, 0.6);
  flex-grow: 0px;
  flex-shrink: 1px;
  z-index: 100;
}
.Modal__modal {
  padding: 20px;
  min-height: 100px;
  min-width: 300px;
  display: flex;
  flex-grow: 0px;
  background-color: #fff;
  flex-direction: column;
  position: relative;
  box-shadow: 0 0 20px 0 #444;
  border-radius: 10px;
}
.Modal__title {
  margin: 0px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}
.Modal__closeButton {
  border: 0px;
  position: absolute;
  right: 20px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 30px;
  height: 30px;
  text-align: center;
  cursor: pointer;
  background-color: #eee;
}
.Modal__closeButton:hover {
  background-color: #ddd;
}
.Modal__content {
  padding-top: 20px;
}

/* END LEXICAL Modal */
