@font-face {
  font-family: 'GTZirkon';
  src: url('/fonts/GTZirkon/GTZirkon-Regular.ttf');
  src: url('/fonts/GTZirkon/GTZirkon-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'GTZirkon';
  src: url('/fonts/GTZirkon/GTZirkon-Light.ttf');
  src: url('/fonts/GTZirkon/GTZirkon-Light.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'GTZirkon';
  src: url('/fonts/GTZirkon/GTZirkon-Italic.ttf');
  src: url('/fonts/GTZirkon/GTZirkon-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'GTZirkon';
  src: url('/fonts/GTZirkon/GTZirkon-Thin.ttf');
  src: url('/fonts/GTZirkon/GTZirkon-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'GTZirkon';
  src: url('/fonts/GTZirkon/GTZirkon-Bold.ttf');
  src: url('/fonts/GTZirkon/GTZirkon-Bold.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Canela';
  src: url('/fonts/Canela/Canela-Thin.ttf');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Canela';
  src: url('/fonts/Canela/Canela-ThinItalic.ttf');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Canela';
  src: url('/fonts/Canela/Canela-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Canela';
  src: url('/fonts/Canela/Canela-BoldItalic.ttf');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'TheDoctorFont';
  src: url('/fonts/TheDoctorFont/TheDoctorFont.ttf');
  font-weight: normal;
  font-style: normal;
}
