@layer tailwind-base, primereact, tailwind-utilities;

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

@import url('./lexical-v2.css');
@import url('./fonts.css');
@import url('react-big-calendar/lib/css/react-big-calendar.css');

/*react-big-calendar style overwriting start*/
.rbc-today {
  /* background-color: rgba(var(--color-primary), 0.2) !important; */
  background: none !important;
}
.rbc-event {
  background-color: rgba(var(--color-primary), 0.9) !important;
}
.rbc-day-slot .rbc-event {
  border-color: rgb(var(--color-primary)) !important;
}
.rbc-show-more {
  color: rgb(var(--color-primary)) !important;
}
.rbc-toolbar button,
.rbc-toolbar button:focus {
  color: rgb(var(--color-primary)) !important;
  border-color: rgba(var(--color-primary), 0.3) !important;
  background: none;
}
.rbc-toolbar button:hover {
  background-color: rgba(var(--color-primary), 0.2) !important;
}
.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  box-shadow: inset 0 3px 5px rgba(var(--color-primary) 0.125);
  background-color: rgb(var(--color-primary)) !important;
  border-color: rgb(var(--color-primary)) !important;
  color: white !important;
}
.rbc-toolbar-label{
  font-family: serif;
  font-weight: bold;
  font-size: 24px;
  text-align: right !important;
}
.rbc-agenda-time-cell {
  border: 1px solid #ddd;
}
/*react-big-calendar style overwriting end*/

.btn-xs {
  @apply px-4;
}
.btn-sm {
  @apply h-7 min-h-max;
}
.btn-circle:where(.btn-sm) {
  @apply w-7;
}
.btn-md {
  @apply h-8 min-h-max;
}
.btn-circle:where(.btn-md) {
  @apply w-8;
}
.btn-lg {
  @apply h-10 min-h-max;
}
.btn-circle:where(.btn-lg) {
  @apply w-10;
}

.btn-outline {
  border-color: rgb(var(--color-border)) !important;
}

.DateInput_input {
  cursor: pointer;
  font-size: 1.25rem !important;
}

.CalendarMonth_caption,
.DateInput_input {
  color: var(--color-primary) !important;
  font-family: serif !important;
  font-weight: bold !important;
}

.DateInput_input::placeholder {
  color: var(--color-primary) !important;
}

.CalendarDay__default {
  border: 1px solid transparent !important;
}

.CalendarDay__blocked_calendar {
  background: #f8f8f8 !important;
  text-decoration: line-through !important;
  opacity: 0.5;
}
.CalendarDay__today {
  color: rgb(var(--color-primary)) !important;
  font-weight: bold !important;
  font-size: 1.1em !important;
}

.CalendarDay__selected:hover {
  background: rgb(var(--color-primary)) !important;
  color: #fff !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}
/* Custom scrollbar styles */
/* width */
.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
/* Track */
.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 999px;
}
/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: rgb(var(--color-primary));
  border-radius: 999px;
}
/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.overflow-shadow:before,
.overflow-shadow::after {
  @apply absolute inset-0 pointer-events-none transition-shadow z-[2];
  content: '';
}

/* .overflow-shadow::after {
  box-shadow: inset -29px 0 35px -29px rgba(0, 0, 0, 0);
}
.overflow-shadow::before {
  box-shadow: inset 29px 0 35px -29px rgba(0, 0, 0, 0);
} */

.overflow-shadow-left:before {
  box-shadow: inset 29px 0 35px -29px rgba(0, 0, 0, 0.4);
}
.overflow-shadow-right::after {
  box-shadow: inset -29px 0 35px -29px rgba(0, 0, 0, 0.4);
}

/* .overflow-shadow-unshifted::after {
  right: 0;
} */
